// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries

import { StateLoaderGlobal } from "./initial-states";

interface SetLoadingGlobalAction {
  type: "SET_LOADING_GLOBAL";
  payload: boolean;
}
export type ActionsParamsLoaderGlobal = SetLoadingGlobalAction;

export type ReducerLoaderGlobal = (
  state: StateLoaderGlobal,
  action: ActionsParamsLoaderGlobal
) => StateLoaderGlobal;

export const reducerLoaderGlobal = (
  state: StateLoaderGlobal,
  action: ActionsParamsLoaderGlobal
): StateLoaderGlobal => {
  switch (action.type) {
    case "SET_LOADING_GLOBAL": {
      const newValue: StateLoaderGlobal = {
        ...state,
        loadingGlobal: action.payload,
      };

      return newValue;
    }

    default:
      return state;
  }
};
