/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router";

import SuspenseLoader from "../../dashboard-template/src/components/SuspenseLoader";
import MobileOnlyLayout from "../../layouts/mobile-only-layout";
import NotFoundRedirect from "../pages/not-found-redirect";
import SidebarLayout from "../../layouts/sidebar-layout";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Admin Dashboards
const AccountDeletion = Loader(
  lazy(() => import("../pages/account-deletion/account-deletion"))
);

//
const AdminTSPPage = Loader(lazy(() => import("../pages/tsp/page-tsp")));
const AdminAATPage = Loader(lazy(() => import("../pages/pages-aat/page-aat")));
const AdminAATFormAdd = Loader(
  lazy(() => import("../pages/pages-aat/aat-form-add"))
);
const AdminAATFormUpsert = Loader(
  lazy(() => import("../pages/pages-aat-upsert/aat-form-upsert"))
);

const routes: RouteObject[] = [
  {
    path: "admin",
    // element: <MobileOnlyLayout />,
    element: <SidebarLayout />,
    children: [
      {
        path: "*",
        element: <NotFoundRedirect />,
      },
      {
        path: "",
        // element: <AdminLogin />,
        element: <Navigate to='tree_planted' />,
      },
      {
        path: "adopt-a-tree",
        children: [
          {
            path: "",
            element: <AdminAATPage />,
          },
          {
            path: "add-new",
            element: <AdminAATFormAdd />,
          },
          {
            path: "edit/:admin_aatstory_id",
            element: <AdminAATFormUpsert />,
          },
        ],
      },
      {
        path: "tree_planted",
        element: <AdminTSPPage />,
      },
    ],
  },
];

export default routes;
