const domain_ = window.location.hostname;
const [sub_, d_] = domain_.split(".");
const [p1_, p2_] = sub_.split("-");
const isLocalHost = window.location.hostname?.indexOf("localhost") > -1;
const idDevDomain = d_ === "komunitree" && p2_ === "dev";
console.log(
  JSON.stringify(
    {
      perrk: d_,
      p2_,
    },
    null,
    2
  )
);

const { APP_URL, APP_VERSION, ENV_ADMIN_DEV } = {
  APP_URL: process.env["APP_URL"],
  // APP_URL: `https://komunitree-dev-svc.azurewebsites.net/`,
  APP_VERSION: process.env["APP_VERSION"],
  ENV_ADMIN_DEV: true,
  // ENV_ADMIN_DEV: !!idDevDomain || !!isLocalHost,
};

export { APP_URL, APP_VERSION, ENV_ADMIN_DEV };
