/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import axios from "axios";

import {
  getTokenLocalDb,
  setTokenLocalDb,
  removeTokenLocalDb,
} from "./utils-local-db";
import { APP_URL, APP_VERSION } from "./web-constants";

const authedAdminAxios = axios.create({
  baseURL: `${APP_URL}admin-rest`,
});

const unAuthedAdminAxios = axios.create({
  baseURL: `${APP_URL}admin-rest`,
});

authedAdminAxios.interceptors.request.use(async (config: any) => {
  // config.headers["Content-Type"] = 'application/x-www-form-urlencoded',
  config.headers.Authorization = `Bearer ${await getTokenLocalDb()}`;
  config.headers["X-CLIENT-WEBADMIN-APP-VERSION"] = `${APP_VERSION}`;
  // console.log("config.headersconfig.headers", config.headers)

  return config;
}, null as any);

// Add a response interceptor
authedAdminAxios.interceptors.response.use(
  async (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.data.idToken) {
      await setTokenLocalDb(response.data.idToken);
    }

    return response;
  },
  async (error) => {
    const { response } = error ?? {};
    const { status } = response ?? {};
    // auto logout
    if (status === 401) {
      await removeTokenLocalDb();
    }

    return Promise.reject(error);
  }
);

unAuthedAdminAxios.interceptors.request.use(async (config: any) => {
  // config.headers["Content-Type"] = 'application/x-www-form-urlencoded',
  config.headers["X-CLIENT-WEBADMIN-APP-VERSION"] = `${APP_VERSION}`;

  // console.log("Starting Request", JSON.stringify(config, null, 2));

  // console.log("config.headersconfig.headers", config.headers)

  return config;
}, null as any);

// Add a response interceptor
unAuthedAdminAxios.interceptors.response.use(
  async (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    // console.log("unAuthedAxios intercept response should be called first");
    // console.log("axiosinterceptresponse unauthed", response.data);
    if (response.data.idToken) {
      await setTokenLocalDb(response.data.idToken);
    }

    return response;
  },
  async (error) => {
    const { response } = error ?? {};
    const { status } = response ?? {};
    // auto logout
    if (status === 401) {
      await removeTokenLocalDb();
    }

    return Promise.reject(error);
  }
);

export { authedAdminAxios, unAuthedAdminAxios };
