import { useCallback, useContext, useEffect } from "react";
import { SessionUserContext } from "../store-context/root-context-provider";
import { StateSessionUser } from "../store-context/reducers/initial-states";
import { KeycloakRoleMappingName } from "../../shared-types/api/api-user";
import { ValidScopes } from "../../shared-utils/valid-scopes";

const useSelectorUserSession = () => {
  const stateSessionUser = useContext<StateSessionUser>(
    SessionUserContext as any
  );

  console.log(
    JSON.stringify(
      {
        msg: "useSelectorUserSessionuseSelectorUserSession",
        stateSessionUser,
      },
      null,
      2
    )
  );

  const hasScopeAdmin = useCallback(
    (st_?: { scope: string }) =>
      stateSessionUser.username &&
      !!(((st_ ?? stateSessionUser)?.scope as KeycloakRoleMappingName) ?? "")
        .split(" ")
        .find(
          (scopeItem: string) =>
            (scopeItem as KeycloakRoleMappingName) === ValidScopes.admin_aat
        ),
    [stateSessionUser]
  );

  const hasScopeAdminAAT = useCallback(
    (st_?: { scope: string }) =>
      stateSessionUser.username &&
      hasScopeAdmin() &&
      !!(((st_ ?? stateSessionUser)?.scope as KeycloakRoleMappingName) ?? "")
        .split(" ")
        .find(
          (scopeItem: string) =>
            (scopeItem as KeycloakRoleMappingName) === ValidScopes.admin_aat
        ),
    [stateSessionUser]
  );

  return {
    stateSessionUser,
    hasScopeAdminAAT,
  };
};

export default useSelectorUserSession;
