/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useRoutes, useLocation } from "react-router-dom";

import { Helmet, HelmetProvider } from "react-helmet-async";
import routerAuthed from "../../routes/admin-authed-router";
// import routerDashboardTemplate from '../../router-test';
import { BrowserRouter } from "react-router-dom";

import { SidebarProvider } from "../../contexts/sidebar-context";
const SidebarProviderCmp = SidebarProvider as any;

import "nprogress/nprogress.css";
import {
  Alert,
  Box,
  CircularProgress,
  CssBaseline,
  Theme,
  ThemeProvider,
} from "@mui/material";
import RootContextProvider, {
  LoaderGlobalContext,
  SessionUserContext,
  SessionUserDispatchContext,
} from "../../store-context/root-context-provider";
import { useContext, useEffect } from "react";
import { SessionUser } from "../../../shared-types/api/api-user";
import getCheckSession from "../../admin-rest-helper/admin-rest-helper";
import useStoreLoaderGlobal from "../../store-context/reducers/use-store-loader-global";

const DashboardAppAuthed = ({ theme }: { theme: Theme }) => {
  const content = useRoutes(routerAuthed);
  const stateUserSession = useContext(SessionUserContext);
  const { stateLoaderGlobal, setLoadingGlobal } = useStoreLoaderGlobal();
  const dispatchUserSession = useContext(SessionUserDispatchContext);

  const ThemeProviderCmp = ThemeProvider;

  const asyncDidMount = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        setLoadingGlobal(true);
        const res = await getCheckSession();
        if (res.data?.data?.username) {
          dispatchUserSession({
            type: "SET_USER_SESSION",
            payload: res.data?.data ?? {},
          });
        }
      } catch (e) {}
      setLoadingGlobal(false);
    }
  };

  useEffect(() => {
    console.log(
      "stateUserSessionstateUserSessionstateUserSession",
      stateUserSession
    );
  }, [stateUserSession]);

  useEffect(() => {
    asyncDidMount();
  }, []);

  const RenderAlerts = () => {
    return (
      <Box sx={{ position: "absolute", zIndex: "3000" }}>
        <Alert variant='filled' severity='success' sx={{ zIndex: "3300" }}>
          This is a filled success Alert.
        </Alert>
        <Alert variant='filled' severity='info' sx={{ zIndex: "3300" }}>
          This is a filled info Alert.
        </Alert>
        <Alert variant='filled' severity='warning' sx={{ zIndex: "3300" }}>
          This is a filled warning Alert.
        </Alert>
        <Alert
          variant='filled'
          severity='error'
          sx={{ zIndex: "3300 !important" }}
        >
          This is a filled error Alert.
        </Alert>
      </Box>
    );
  };

  return (
    <ThemeProviderCmp theme={theme}>
      <CssBaseline />
      {/* <RenderAlerts /> */}
      {content}
      {stateLoaderGlobal.loadingGlobal && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 9,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255,255,255,0.5)",
          }}
        >
          <CircularProgress
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              /* bring your own prefixes */
              transform: "translate(-50%, -50%)",
              zIndex: 1301,
            }}
            size={64}
            disableShrink
            thickness={3}
            color='secondary'
          />
        </div>
      )}
    </ThemeProviderCmp>
  );
};

const DashboardAuthed = ({ theme }: { theme: Theme }) => {
  return (
    <HelmetProvider>
      <SidebarProviderCmp>
        <BrowserRouter>
          <RootContextProvider>
            <DashboardAppAuthed theme={theme} />
          </RootContextProvider>
        </BrowserRouter>
      </SidebarProviderCmp>
    </HelmetProvider>
  );
};

export default DashboardAuthed;
