enum LOCAL_DB_KEYS {
  token = "token",
}

export const getTokenLocalDb = async () =>
  localStorage.getItem(LOCAL_DB_KEYS.token);
export const setTokenLocalDb = async (value: string) =>
  localStorage.setItem(LOCAL_DB_KEYS.token, value);
export const removeTokenLocalDb = async () =>
  localStorage.removeItem(LOCAL_DB_KEYS.token);
