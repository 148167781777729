/* eslint-disable @nrwl/nx/enforce-module-boundaries */

import { Suspense, lazy } from "react";
import { Navigate, RouteObject } from "react-router";
import ADADAD from "../pages/account-deletion/account-deletion";

import SuspenseLoader from "../../dashboard-template/src/components/SuspenseLoader";
import MobileOnlyLayout from "../../layouts/mobile-only-layout";
import NotFoundRedirect from "../pages/not-found-redirect";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Admin Dashboards
const AccountDeletion = Loader(
  lazy(() => import("../pages/account-deletion/account-deletion"))
);

//
const AdminLogin = Loader(lazy(() => import("../pages/login/login")));

const routes: RouteObject[] = [
  {
    path: "*",
    element: <NotFoundRedirect />,
  },
  {
    path: "",
    element: <Navigate to='admin' />,
  },
  {
    path: "account-deletion",
    element: <MobileOnlyLayout />,
    children: [
      {
        path: "",
        element: <AccountDeletion />,
      },
    ],
  },
  {
    path: "admin",
    element: <MobileOnlyLayout />,
    children: [
      {
        path: "",
        element: <AdminLogin />,
      },
    ],
  },
];

export default routes;
