import type { AuthScope } from "../shared-types/api/api-user";

export enum ValidScopes {
  user = "user",
  admin = "admin",
  admin_aat = "admin_aat",
  proxy_planter = "proxy_planter",
  payment_gtw_gcash = "payment_gtw_gcash",
  dbb_verifed_mobile_number = "dbb_verifed_mobile_number",
}

// dbb prefix = scope that are database-based scopes

const scopesAllValidArray: AuthScope[] = [
  ValidScopes.user,
  ValidScopes.admin,
  ValidScopes.admin_aat,
  ValidScopes.proxy_planter,
  ValidScopes.payment_gtw_gcash,
  ValidScopes.dbb_verifed_mobile_number,
];

const isArrayASubsetOfAnother = (
  scopesToCheck: string[],
  acceptableScopes: string[]
) => scopesToCheck.every((val) => acceptableScopes.includes(val));

const isScopeValid = (scopeArrToCheck: AuthScope[]) =>
  isArrayASubsetOfAnother(scopeArrToCheck, scopesAllValidArray);

export default isScopeValid;
export { isScopeValid, scopesAllValidArray, isArrayASubsetOfAnother };
