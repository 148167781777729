import { FC, ReactNode } from 'react';
import { Box, alpha, lighten, useTheme, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './sidebar';
import Header from './header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const useRenderHeaderOnlyOnBigScreen = () => {
  const theme = useTheme();
  const isLGScreen = useMediaQuery(theme.breakpoints.down('lg')) === true;

  const EmptyPlaceHolder = () => <></>;

  const HeaderCmp = Header;

  return {
    HeaderHideable: isLGScreen ? HeaderCmp : EmptyPlaceHolder,
  };
};

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const { HeaderHideable } = useRenderHeaderOnlyOnBigScreen();
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: 1,
        height: '100%',

        '.MuiPageTitle-wrapper': {
          background:
            theme.palette.mode === 'dark'
              ? theme.colors.alpha.trueWhite[5]
              : theme.colors.alpha.white[50],
          marginBottom: `${theme.spacing(4)}`,
          boxShadow:
            theme.palette.mode === 'dark'
              ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
              : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`,
        },
      }}
    >
      <HeaderHideable />
      <Sidebar />
      <Box
        sx={{
          position: 'relative',
          zIndex: 5,
          display: 'block',
          flex: 1,
          // remove empty space at the top intended for sticky header
          // pt: `${isLGScreen ? '0px !important' : theme.header.height}`,
          [theme.breakpoints.up('lg')]: {
            ml: `${theme.sidebar.width}`,
          },
          [theme.breakpoints.down('lg')]: {
            pt: `${theme.header.height}`,
          },
        }}
      >
        <Box display="block">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarLayout;
