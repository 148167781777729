import { format } from "date-fns";
import { PersistStatusNames$ } from "../shared-constants/shared-constants";
import { KeycloakRoleMappingName } from "../shared-types/api/api-user";
import { isAfter } from "./date-util";
import { ValidScopes } from "./valid-scopes";

const isNumber = (value: any) => !isNaN(parseFloat(value));

const setItemPersistentStatusInfo = (
  item: any,
  status: keyof Partial<{ [key in PersistStatusNames$]: PersistStatusNames$ }>
): any => {
  let newItem = {
    ...item,
    persistentStatusInfo$: status.toString(),
  };

  return newItem;
};

const resizeToStandard = (
  {
    heightInPx,
    widthInPx,
  }: {
    heightInPx: number;
    widthInPx: number;
  },
  // targetPPI = 72 // web standards
  targetPPI = 300
) => {
  // Calculate the diagonal size in inches
  const diagonalInPixels = Math.sqrt(widthInPx ** 2 + heightInPx ** 2);
  const diagonalSizeInInches = diagonalInPixels / targetPPI;

  // Calculate the new dimensions at the target PPI
  const newWidthInPixels = Math.round(
    (widthInPx * targetPPI) / diagonalInPixels
  );
  const newHeightInPixels = Math.round(
    (heightInPx * targetPPI) / diagonalInPixels
  );

  return { newWidthInPixels, newHeightInPixels };
};

// Example usage
// const expoCameraWidth = 1920; // Replace with the actual Expo Camera width
// const expoCameraHeight = 1080; // Replace with the actual Expo Camera height

// const scaledDimensions = calculateAndScaleDimensions(expoCameraWidth, expoCameraHeight);
// console.log(`New width: ${scaledDimensions.newWidthInPixels}px`);
// console.log(`New height: ${scaledDimensions.newHeightInPixels}px`);

const scaleDimToMin = (
  dim: {
    width: number;
    height: number;
  },
  MIN_LEN = 720
): { width: number; height: number } => {
  // const MIN_LEN = 720;
  const { width, height } = dim;
  const ratioWidthVS720 = Math.floor((width / MIN_LEN) * 100000) / 100000;
  const ratioHeightVS720 = Math.floor((height / MIN_LEN) * 100000) / 100000;

  // width and height is > 720
  if (ratioWidthVS720 > 1 && ratioHeightVS720 > 1) {
    // this image has greater width than height
    const multiplier =
      ratioWidthVS720 > ratioHeightVS720
        ? 1 / ratioHeightVS720
        : 1 / ratioWidthVS720;

    return {
      width: Math.floor(width * multiplier),
      height: Math.floor(height * multiplier),
    };
  }

  return dim;
};

type TypeOpts = null | {
  maxLength: number;
  aspectRatio: number;
};

const scaleDimToMaxPortrait = (
  dim: {
    width: number;
    height: number;
  },
  _opts?: TypeOpts
): { width: number; height: number } => {
  const options = (_opts ?? {}) as TypeOpts;
  const MAX_LEN = options?.maxLength ?? 720;
  // const MIN_LEN = 720;
  const { width, height } = dim;
  const ratioWidthVS720 = Math.floor((width / MAX_LEN) * 100000) / 100000;
  const ratioHeightVS720 = Math.floor((height / MAX_LEN) * 100000) / 100000;

  // width and height is > 720
  if (ratioWidthVS720 > 1 && ratioHeightVS720 > 1) {
    // this image width is has greater than it's height
    const multiplier =
      ratioHeightVS720 > ratioWidthVS720
        ? 1 / ratioHeightVS720
        : 1 / ratioWidthVS720;

    return {
      width: Math.floor(width * multiplier),
      height: Math.floor(height * multiplier),
    };
  }

  return dim;
};

const scaleTo720Min = (dim: {
  width: number;
  height: number;
}): { width: number; height: number } => {
  return scaleDimToMin(dim, 720);
};

const scaleTo720Max = (dim: {
  width: number;
  height: number;
}): { width: number; height: number } => {
  const MAX_LEN = 720;
  const { width, height } = dim;
  const ratioWidthVS720 = Math.round((width / MAX_LEN) * 100000) / 100000;
  const ratioHeightVS720 = Math.round((height / MAX_LEN) * 100000) / 100000;

  console.log(
    JSON.stringify(
      {
        log: {
          width,
          height,
          ratioHeightVS720,
          ratioWidthVS720,
          widthcom: ratioWidthVS720 > 1,
          heightcomp: ratioHeightVS720 > 1,
        },
      },
      null,
      2
    )
  );

  // width and height is > 720
  if (ratioWidthVS720 > 1 && ratioHeightVS720 > 1) {
    // this image width is has greater than it's height
    const multiplier =
      ratioWidthVS720 > ratioHeightVS720
        ? 1 / ratioWidthVS720
        : 1 / ratioHeightVS720;

    return {
      width: width * multiplier,
      height: height * multiplier,
    };
  }

  return dim;
};

function calculateBottomY(
  dimScreen: { width: number; height: number },
  rect_aspect_ratio: number
): {
  topY: number;
  bottomY: number;
  rect_width: number;
} {
  const { width: screen_width, height: screen_height } = dimScreen;
  // Calculate the width and height of the scaled rectangle to fit the screen width
  // const rect_width = screen_width;
  const rect_height = screen_width / rect_aspect_ratio;
  const rect_width = screen_height * rect_aspect_ratio;

  // Calculate the centered top y and bottom y coordinates
  const y_center = screen_height / 2,
    y_top = y_center - rect_height / 2,
    y_bottom = y_center + rect_height / 2;

  // console.log(
  //   JSON.stringify(
  //     {
  //       rect_height,
  //       rect_aspect_ratio,
  //       y_center,
  //       y_top,
  //       y_bottom,
  //     },
  //     null,
  //     2
  //   )
  // );
  return {
    topY: y_top,
    bottomY: y_bottom,
    rect_width,
  };
}

const hasScopePaymentGTWGCashUtil = (st_: { scope: string }) =>
  !!((st_?.scope as KeycloakRoleMappingName) ?? "")
    .split(" ")
    .find(
      (scopeItem: string) =>
        (scopeItem as KeycloakRoleMappingName) === ValidScopes.payment_gtw_gcash
    );

const hexToRGB = (hex: string, alpha: number): string => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

const headerNameManualMap = (str: string): string => {
  switch (str) {
    case "Tree Sapling Planted Id":
      return "ID";
    // case "Photo Taken Timestamp": {
    //   return "Date";
    // }
    case "Username": {
      return "Planter";
    }
    case "Common Name": {
      return "Genus";
    }
    case "Geoloc Is High Accuracy": {
      return "Is Precise Location?";
    }
    case "Geoloc Has Internet": {
      return "Offline geoloc?";
    }
  }

  return str;
};

const awaitableDelay = async (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, ms ?? 1000);
  });
};

const getProportionalHeightByWidth = (
  dim: {
    width: number;
    height: number;
  },
  width: number
): number => {
  return (width * dim.height) / dim.width;
};

const statusesAAT = {
  draft: 1,
  paused: 2,
  active: 3,
  inactive: 4,
};

const getAATStatusCodeFromStatus = (status: string): number => {
  return (statusesAAT as any)[status] as number;
};

const getFloatForEqualityTest = (number: string | number): number => {
  return Math.round(parseFloat("" + number) * 1000);
};

const floatCompare = (num1: number, num2: number): number => {
  const rounded1 = getFloatForEqualityTest(num1),
    rounded2 = getFloatForEqualityTest(num2);

  return rounded1 - rounded2;
};

const delimiterPaypalCustomId = "--_--";
interface ExtractPaypalCustomIdInfo {
  accountId: number;
  userSub: string;
  aatID: number;
}

const isAppCenterRetired = (): boolean => {
  const retireDate = new Date(format(new Date(2025, 1, 31), "yyyy-MM-dd"));
  const currentDate = new Date(format(new Date(), "yyyy-MM-dd"));

  return isAfter(currentDate, retireDate);
};

interface ExtractPaypalCustomIdInfo {
  accountId: number;
  userSub: string;
  aatID: number;
}

const createPaypalCustomId = ({
  userSub,
  accountId,
  aatID,
}: ExtractPaypalCustomIdInfo): string => {
  return `${userSub}${delimiterPaypalCustomId}${accountId}${delimiterPaypalCustomId}${aatID}`;
};

const extractPaypalCustomIdInfo = (
  customId: string
): ExtractPaypalCustomIdInfo => {
  try {
    const [userSub, accountId, aatID] = customId.split(delimiterPaypalCustomId);

    return {
      userSub,
      accountId: parseInt(accountId, 10),
      aatID: parseInt(aatID, 10),
    };
  } catch (e: any) {
    console.log(
      "something went wrong in extractPaypalCustomIdInfo",
      e?.message
    );
  }

  throw Error("something went wrong in extractPaypalCustomIdInfo");
};

const ucWords = (str: string): string => {
  return str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
};

const getDirtyValues = <
  CurrentValues extends Record<keyof DirtyFields, unknown>,
  DirtyFields extends Record<string, unknown>
>(
  currentValues: CurrentValues,
  dirtyFields: DirtyFields
): Partial<typeof dirtyFields> => {
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    // if (currentValues[key] === undefined) return prev;
    const isBothFloatAndEqual =
      isNumber(currentValues[key]) && isNumber(dirtyFields[key])
        ? Math.round(parseFloat((currentValues as any)[key]) * 100000) ===
          Math.round(parseFloat((dirtyFields as any)[key]) * 100000)
        : false;
    if (currentValues[key] !== dirtyFields[key] && !isBothFloatAndEqual) {
      return {
        ...prev,
        [key]: dirtyFields[key],
        // [key]:
        //   typeof dirtyFields[key] === "object"
        //     ? getDirtyValues(
        //         currentValues[key] as CurrentValues,
        //         dirtyFields[key] as DirtyFields
        //       )
        //     : currentValues[key],
      };
    }

    return prev;
  }, {});

  return dirtyValues;
};

const getDirtyFields = (obj1Original: any, obj2New: any) => {
  Object.keys(obj1Original).forEach((key) => {
    const isOriginalNumber = typeof obj1Original[key] === "number",
      isNewNumber = typeof obj2New[key] === "number",
      isOriginalBoolean = typeof obj1Original[key] === "boolean",
      isNewBoolean = typeof obj2New[key] === "boolean",
      isOriginalString = typeof obj1Original[key] === "string",
      isNewString = typeof obj2New[key] === "string",
      isOriginalNullOrUndefined =
        obj1Original[key] === null || obj1Original[key] === undefined,
      isNewNullOrUndefined =
        obj2New[key] === null || obj2New[key] === undefined;

    const isInputNull = isOriginalNumber === null || isNewString === null;
    const isOriginalSimple =
        isOriginalNumber ||
        isOriginalBoolean ||
        isOriginalString ||
        isOriginalNullOrUndefined,
      isNewSimple =
        isNewNumber || isNewBoolean || isNewString || isNewNullOrUndefined;

    if (isInputNull) {
      throw new Error("getDirtyFields can't handle null values yet.");
    }
    // if (!isOriginalSimple || !isNewSimple) {
    //   throw new Error("getDirtyFields can't handle complex values yet.");
    // }
    if (!isNewSimple) {
      throw new Error("getDirtyFields can't handle complex values yet.");
    }
  });

  return getDirtyValues(obj1Original, obj2New);
};

export {
  awaitableDelay,
  hasScopePaymentGTWGCashUtil,
  setItemPersistentStatusInfo,
  resizeToStandard,
  scaleTo720Min,
  scaleTo720Max,
  calculateBottomY,
  hexToRGB,
  isNumber,
  headerNameManualMap,
  scaleDimToMin,
  scaleDimToMaxPortrait,
  getAATStatusCodeFromStatus,
  getProportionalHeightByWidth,
  floatCompare,
  isAppCenterRetired,
  createPaypalCustomId,
  extractPaypalCustomIdInfo,
  ucWords,
  getDirtyFields,
};

export type { ExtractPaypalCustomIdInfo };
