import "@emotion/react";

import { Theme } from "@emotion/react";
import { hexToRGB } from "../shared-utils/shared-js-utils";

declare module "@emotion/react" {
  export interface Theme {
    name: "light" | "dark";
    colors: {
      gradients: {
        blue1: string;
        blue2: string;
        blue3: string;
        blue4: string;
        blue5: string;
        orange1: string;
        orange2: string;
        orange3: string;
        purple1: string;
        purple3: string;
        pink1: string;
        pink2: string;
        green1: string;
        green2: string;
        black1: string;
        black2: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      bgAlpha: {
        black: {
          80: string;
        };
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
          550: string;
        };
        main: {
          450: string;
          600: string;
          950: string;
        };
        grey: {
          50: string;
          105: string;
          110: string;
          320: string;
          450: string;
          580: string;
          535: string;
          600: string;
          ALPHA600: string;
          645: string;
        };
        trueWhite: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
          999: string;
        };
        red: {
          870: string;
        };
      };
      secondary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
    };
    zIndex: {
      drawer: number;
    };
  }
}

export const palette = {
  navy: {
    50: "#E9ECF2",
    200: "#4668AD",
    300: "#34528D",
    400: "#23365D", // splash background
    500: "#1E2738",
  },

  lightBlue: {
    100: "#EEF5F9",
    150: "#E4F2FD",
    200: "#DAEAF8",
    400: "#4895DD",
  },

  green: {
    50: "#DBF0EA",
    100: "#a6d9c7",
    200: "#88CCB9",
    300: "#4DB296",
    400: "#47A382",
    500: "#0B8457", // k-green
    600: "#096C47",
    950: "#334F61", // dark-green
  },

  leafyGreen: {
    300: "#3A8B18",
  },

  cream: {
    100: "#fff7e4",
    200: "#fff1d3",
    300: "#ffe4a6",
    400: "#ffd67a",
    500: "#E9B94F",
  },

  red: {
    50: "#FFECEB",
    100: "#FFD6D3",
    200: "#FFC1BD",
    300: "#FF9892",
    400: "#FF847C",
    500: "#E57770",
    850: "#9E251C",
    860: "#D21716",
    870: "#d92f40",
  },

  grey: {
    50: "#F3F5F8",
    100: "#F8FAFC",
    105: "#F8F8F8",
    110: "#FFF",
    200: "#EBF0F4",
    300: "#D7DEE5",
    320: "#D1D4D9",
    400: "#AAB5C0",
    450: "#9B9B9B",
    500: "#74808B",
    535: "#7D8697",
    580: "#677185",
    600: "#49505E",
    645: "#5A6375",
    700: "#353D45",
    800: "#272E34",
    900: "#1D2226",
  },
  black30: "#393E49",
  black2: "#334F6",
  black3: "#262A32",
  blackPure: "#000",
  white: "#FFF",
  whiteGrey: "#F3F5F8",
};

export const COLOR_DEFAULT_SCREEN_BG = palette.grey["110"];

export const themeBase: Theme = {
  name: "light",
  colors: {
    gradients: {
      blue1: palette.navy[200],
      blue2: palette.navy[200],
      blue3: palette.navy[200],
      blue4: palette.navy[200],
      blue5: palette.navy[200],
      orange1: palette.navy[200],
      orange2: palette.navy[200],
      orange3: palette.navy[200],
      purple1: palette.navy[200],
      purple3: palette.navy[200],
      pink1: palette.navy[200],
      pink2: palette.navy[200],
      green1: palette.navy[200],
      green2: palette.navy[200],
      black1: palette.navy[200],
      black2: palette.navy[200],
    },
    shadows: {
      success: palette.navy[200],
      error: palette.navy[200],
      primary: palette.navy[200],
      warning: palette.navy[200],
      info: palette.navy[200],
    },
    bgAlpha: {
      black: {
        80: "rgba(0, 0, 0, 0.8)",
      },
    },
    alpha: {
      white: {
        5: palette.navy[200],
        10: palette.navy[200],
        30: palette.navy[200],
        50: palette.navy[200],
        70: palette.navy[200],
        100: palette.navy[200],
        550: palette.whiteGrey,
      },
      main: {
        450: palette.leafyGreen["300"],
        600: palette.green["600"],
        950: palette.green[950],
      },
      grey: {
        50: palette.grey[50],
        105: palette.grey[105],
        110: palette.grey[110],
        320: palette.grey[320],
        450: palette.grey[450],
        580: palette.grey[580],
        535: palette.grey[535],
        600: palette.grey[600],
        ALPHA600: hexToRGB(palette.grey[580], 0.88),
        645: palette.grey[645],
      },
      trueWhite: {
        5: palette.white,
        10: palette.navy[200],
        30: palette.navy[200],
        50: palette.navy[200],
        70: palette.navy[200],
        100: palette.navy[200],
      },
      black: {
        5: palette.navy[200],
        10: palette.navy[200],
        30: palette.black30,
        50: palette.black2,
        70: palette.black3,
        100: palette.navy[200],
        999: palette.blackPure,
      },
      red: {
        870: palette.red["870"],
      },
    },
    secondary: {
      lighter: palette.white,
      light: palette.white,
      main: palette.lightBlue["150"],
      dark: palette.white,
    },
    primary: {
      lighter: palette.white,
      light: palette.white,
      main: palette.green["500"],
      dark: palette.black3,
    },
    success: {
      lighter: palette.white,
      light: palette.white,
      main: palette.white,
      dark: palette.white,
    },
    warning: {
      lighter: palette.white,
      light: palette.white,
      main: palette.red["850"],
      dark: palette.red["860"],
    },
    error: {
      lighter: palette.white,
      light: palette.white,
      main: palette.white,
      dark: palette.white,
    },
    info: {
      lighter: palette.white,
      light: palette.white,
      main: palette.white,
      dark: palette.white,
    },
  },
  zIndex: {
    drawer: 1,
  },
};

export const themeLight: Theme = {
  ...themeBase,
  name: "light",
};

export const themeDark: Theme = {
  ...themeLight,
  name: "dark",
  colors: {
    ...themeLight.colors,
  },
} as const;

export function getTheme(isDark?: boolean): Theme {
  if (isDark === true) {
    return themeDark;
  }

  return themeLight;
}
