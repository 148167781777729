import { Suspense, lazy, useContext } from "react";

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SidebarContext } from "../../../../app/contexts/sidebar-context";

import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
// import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import IconFmdGood from "@mui/icons-material/FmdGood";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";
import BeachAccessTwoToneIcon from "@mui/icons-material/BeachAccessTwoTone";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";
import FilterVintageTwoToneIcon from "@mui/icons-material/FilterVintageTwoTone";
import HowToVoteTwoToneIcon from "@mui/icons-material/HowToVoteTwoTone";
import LocalPharmacyTwoToneIcon from "@mui/icons-material/LocalPharmacyTwoTone";
import RedeemTwoToneIcon from "@mui/icons-material/RedeemTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import TrafficTwoToneIcon from "@mui/icons-material/TrafficTwoTone";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import ChromeReaderModeTwoToneIcon from "@mui/icons-material/ChromeReaderModeTwoTone";
import WorkspacePremiumTwoToneIcon from "@mui/icons-material/WorkspacePremiumTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";
import RenderDashboardMenus, { SubMenuWrapper } from "./render-rbac-menus";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};
    zIndex: 555;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

import SuspenseLoader from "../../../../app/components/loader/suspense-loader";

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// const LazyRenderDashboardMenus = Loader(
//   lazy(() => import("./render-rbac-menus"))
// );

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);

  const tododebug = true;

  const onLogOut = async () => {
    if (!confirm("Are you sure you want to logout?")) {
      return;
    }
    localStorage.removeItem("token");
    location.replace(`/admin`);
  };

  if (tododebug) {
    return (
      <MenuWrapper>
        {/* <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/overview"
                    startIcon={<DesignServicesTwoToneIcon />}
                  >
                    Overview
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List> */}
        <RenderDashboardMenus />
        {/* 
        <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Management
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            {/ * <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/management/transactions"
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Transactions List
                </Button>
              </ListItem>
            </List> * /}
            <List component='div'>
              <ListItem component='div'>
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to='/admin/integrations'
                  startIcon={<TableChartTwoToneIcon />}
                >
                  Integration
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}

        <List
          component='div'
          subheader={
            <ListSubheader component='div' disableSticky>
              Profile
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component='div'>
              <ListItem component='div'>
                <Button
                  // disableRipple
                  // component={RouterLink}
                  onClick={onLogOut}
                  // to='logout'
                  // startIcon={<TableChartTwoToneIcon />}
                >
                  Logout
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    );
  }

  return (
    <MenuWrapper>
      {/* <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/overview"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Overview
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}
      <List
        component='div'
        subheader={
          <ListSubheader component='div' disableSticky>
            Dashboards
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component='div'>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/dashboards/crypto'
                startIcon={<BrightnessLowTwoToneIcon />}
              >
                Overview
              </Button>
            </ListItem>
            {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/messenger"
                  startIcon={<MmsTwoToneIcon />}
                >
                  Messenger
                </Button>
              </ListItem> */}
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/management/locations'
                startIcon={<IconFmdGood />}
              >
                Locations
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
      <List
        component='div'
        subheader={
          <ListSubheader component='div' disableSticky>
            Management
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component='div'>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/management/transactions'
                startIcon={<TableChartTwoToneIcon />}
              >
                Transactions List
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
      <List
        component='div'
        subheader={
          <ListSubheader component='div' disableSticky>
            Accounts
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component='div'>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/management/profile/details'
                startIcon={<AccountCircleTwoToneIcon />}
              >
                User Profile
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/management/profile/settings'
                startIcon={<DisplaySettingsTwoToneIcon />}
              >
                Account Settings
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
      <List
        component='div'
        subheader={
          <ListSubheader component='div' disableSticky>
            Components
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component='div'>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/buttons'
                startIcon={<BallotTwoToneIcon />}
              >
                Buttons
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/modals'
                startIcon={<BeachAccessTwoToneIcon />}
              >
                Modals
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/accordions'
                startIcon={<EmojiEventsTwoToneIcon />}
              >
                Accordions
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/tabs'
                startIcon={<FilterVintageTwoToneIcon />}
              >
                Tabs
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/badges'
                startIcon={<HowToVoteTwoToneIcon />}
              >
                Badges
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/tooltips'
                startIcon={<LocalPharmacyTwoToneIcon />}
              >
                Tooltips
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/avatars'
                startIcon={<RedeemTwoToneIcon />}
              >
                Avatars
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/cards'
                startIcon={<SettingsTwoToneIcon />}
              >
                Cards
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/components/forms'
                startIcon={<TrafficTwoToneIcon />}
              >
                Forms
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
      <List
        component='div'
        subheader={
          <ListSubheader component='div' disableSticky>
            Extra Pages
          </ListSubheader>
        }
      >
        <SubMenuWrapper>
          <List component='div'>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/status/404'
                startIcon={<CheckBoxTwoToneIcon />}
              >
                Error 404
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/status/500'
                startIcon={<CameraFrontTwoToneIcon />}
              >
                Error 500
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/status/coming-soon'
                startIcon={<ChromeReaderModeTwoToneIcon />}
              >
                Coming Soon
              </Button>
            </ListItem>
            <ListItem component='div'>
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to='/status/maintenance'
                startIcon={<WorkspacePremiumTwoToneIcon />}
              >
                Maintenance
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
