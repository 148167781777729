import { useContext } from "react";
import {
  LoaderGlobalContext,
  LoaderGlobalDispatchContext,
} from "../root-context-provider";

const useStoreLoaderGlobal = () => {
  const stateLoaderGlobal = useContext(LoaderGlobalContext);
  const isLoadingGlobal = stateLoaderGlobal.loadingGlobal === true;
  const dispatchLoaderGlobal = useContext(LoaderGlobalDispatchContext);

  const setLoadingGlobal = (bool: boolean) => {
    dispatchLoaderGlobal({
      type: "SET_LOADING_GLOBAL",
      payload: bool,
    });
  };

  return {
    isLoadingGlobal,
    stateLoaderGlobal,
    dispatchLoaderGlobal,
    setLoadingGlobal,
  };
};

export default useStoreLoaderGlobal;
