import { styled } from "@mui/material";

const TextBase = styled("p")`
  font-family: Lato-Regular;
`;

const StyledDescription12 = styled(TextBase)`
  font-size: 12px;
`;

export { StyledDescription12 };
export default StyledDescription12;
