// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries

import { initialStateSessionUser, StateSessionUser } from "./initial-states";

interface SetUserSessionAction {
  type: "SET_USER_SESSION";
  payload: StateSessionUser;
}

interface ClearUserSessionAction {
  type: "CLEAR_USER_SESSION";
}

export type ActionsParamsUserSession =
  | SetUserSessionAction
  | ClearUserSessionAction;

export type ReducerUserSession = (
  state: StateSessionUser,
  action: ActionsParamsUserSession
) => StateSessionUser;

export const reducerSessionUser = (
  state: StateSessionUser,
  action: ActionsParamsUserSession
): StateSessionUser => {
  switch (action.type) {
    case "SET_USER_SESSION": {
      const newValue = {
        ...state,
        ...action.payload,
      };

      return newValue;
    }

    case "CLEAR_USER_SESSION": {
      return {
        ...initialStateSessionUser,
      } as StateSessionUser;
    }

    default:
      return state;
  }
};
