import { SessionUser } from "../../../shared-types/api/api-user";

export type StateSessionUser = SessionUser;
type InitStateSessionUser = Partial<StateSessionUser>;

export const initialStateSessionUser: StateSessionUser | InitStateSessionUser =
  {
    username: null,
    account_id: null,
    scope: null,
    firstname: null,
    lastname: null,
    access_token: null,
    exp: null,
    iat: null,
    sub: null,
    // profile_image: null,
  } as InitStateSessionUser;

export type StateLoaderGlobal = {
  loadingGlobal: null | boolean;
};

export const initialLoaderGlobal: StateLoaderGlobal = {
  loadingGlobal: null,
};
