// import router from "./src/app/routes/admin-router";

// import { getTheme } from "./src/shared-styles/emotion-theme.d";
// import {
//   SharedCtrlTextInput,
//   SharedTextInputField,
// } from "./src/shared-ui/text-input/text-input";

import React from "react";
import { createRoot } from "react-dom/client";
import DashboardUnauthed from "./src/app/pages/dashboard/dashboard-unauthed";
import { themeCreator } from "./src/dashboard-template/src/theme/base";
import { useCallback } from "react";

import { getTheme } from "./src/shared-styles/emotion-theme.d";
import { getTokenLocalDb } from "./src/utils/utils-local-db";
import { useEffect, useState } from "react";
import DashboardAuthed from "./src/app/pages/dashboard/dashboard-authed";
import { createTheme } from "@mui/material";

const themeDashboardTemplate = themeCreator("PureLightTheme");

// ideally, only use these, but for now we needed some values from the dashboard-template
const theme = createTheme({
  ...themeDashboardTemplate,
  ...getTheme(),
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         background: "blue",
  //       },
  //       text: {
  //         color: "brown",
  //       },
  //     },
  //   },
  //   MuiButtonBase: {
  //     styleOverrides: {
  //       root: {
  //         color: "red",
  //       },
  //     },
  //   },
  // },
  typography: {
    fontFamily: [
      "Lato-Regular",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const validWhitelistedSpecialResources = [
  "account-deletion",
  // "account-deletion-link",
];
const App = () => {
  const [loadingIdToken, setLoadingIdToken] = useState<null | boolean>(null);
  const [token, setToken] = useState<null | string>(null);
  // todo const theme = useTheme()
  // const theme = themeCreator("PureLightTheme");

  const onDidMountAsync = async () => {
    setLoadingIdToken(true);
    const token = await getTokenLocalDb();

    // not sure how long is an idToken, I think more than 255chars
    if (token?.length > 11) {
      setToken(token);
    } else {
      if (window.location.href) {
        const [protocol, hostname, firstsub, secondsub] = window.location.href
          .split("/")
          .filter((part) => part !== "");
        console.log("firstsub is it/", firstsub);
        if (firstsub === "admin" && !secondsub) {
        } else {
          console.log("firstsub is it/", firstsub);
          if (
            validWhitelistedSpecialResources.some((whiteListed) =>
              // it matches even if firstSub has extra characters to be trimmed left/right
              firstsub?.includes(whiteListed)
            )
          ) {
          } else {
            // then this is not login page
            console.log("should have already called location.replace(admin)");
            // const tododebug1 = 1 location.replace(`/admin`);
          }
        }
      }
    }

    setLoadingIdToken(false);
  };

  useEffect(() => {
    onDidMountAsync();
  }, []);

  // todo: instead, render a spinner
  if (loadingIdToken === null || loadingIdToken === true) {
    return <></>;
  } else if (!token) {
    return <DashboardUnauthed theme={theme} />;
  } else {
    return <DashboardAuthed theme={theme} />;
  }
};

const container = document.getElementById("app");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
