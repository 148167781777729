import { FC, ReactNode } from "react";
import { Box, alpha, lighten, useTheme, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";

// import Sidebar from './sidebar';
import Header from "./header";

interface MobileOnlyLayoutProps {
  children?: ReactNode;
}

const MobileOnlyLayout: FC<MobileOnlyLayoutProps> = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        flex: 1,
        height: "100%",

        ".MuiPageTitle-wrapper": {
          background: theme.colors.alpha.white[50],
          marginBottom: `4px`,
          boxShadow: `0px 2px 4px -3px ${alpha(
            theme.colors.alpha.black[100],
            0.1
          )}, 0px 5px 12px -4px ${alpha(theme.colors.alpha.black[100], 0.05)}`,
        },
      }}
    >
      <Header />
      {/* <Sidebar /> */}
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          display: "block",
          flex: 1,
          /*
          // remove empty space at the top intended for sticky header
          // pt: `${isLGScreen ? '0px !important' : theme.header.height}`,
          [theme.breakpoints.up("lg")]: {
            ml: `${theme.sidebar.width}`,
          },
          [theme.breakpoints.down("lg")]: {
            pt: `${theme.header.height}`,
          },*/
        }}
      >
        <Box display='block'>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MobileOnlyLayout;
