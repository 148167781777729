/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useRoutes, useLocation } from "react-router-dom";

import { Helmet, HelmetProvider } from "react-helmet-async";
import router from "../../routes/admin-router";
// import routerDashboardTemplate from '../../router-test';
import { BrowserRouter } from "react-router-dom";

import { SidebarProvider } from "../../contexts/sidebar-context";
const SidebarProviderCmp = SidebarProvider as any;

import "nprogress/nprogress.css";
import { CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
const DashboardApp = ({ theme }: { theme: Theme }) => {
  console.log("app/routes/admin-router.tsx is loaded");
  const content = useRoutes(router);

  const ThemeProviderCmp = ThemeProvider;

  useEffect(() => {
    const importCSS = async () => {
      await import("../../../../style-body-margin-0.css");
    };
    importCSS();
  }, []);

  return (
    <ThemeProviderCmp theme={theme}>
      <CssBaseline />
      {content}
    </ThemeProviderCmp>
  );
};

const DashboardUnauthed = ({ theme }: { theme: Theme }) => {
  return (
    <HelmetProvider>
      <SidebarProviderCmp>
        <BrowserRouter>
          <DashboardApp theme={theme} />
        </BrowserRouter>
      </SidebarProviderCmp>
    </HelmetProvider>
  );
};

export default DashboardUnauthed;
