import { createContext, Dispatch, useReducer } from "react";
import {
  initialLoaderGlobal,
  initialStateSessionUser,
  StateLoaderGlobal,
  StateSessionUser,
} from "./reducers/initial-states";

import {
  ActionsParamsUserSession,
  reducerSessionUser,
  ReducerUserSession,
} from "./reducers/reducer-user-session";
import {
  ActionsParamsLoaderGlobal,
  reducerLoaderGlobal,
  ReducerLoaderGlobal,
} from "./reducers/reducer-loader-global";

export const SessionUserContext = createContext(initialStateSessionUser);
export const SessionUserDispatchContext = createContext(
  (() => {}) as Dispatch<ActionsParamsUserSession>
);

export const LoaderGlobalContext = createContext(initialLoaderGlobal);
export const LoaderGlobalDispatchContext = createContext(
  (() => {}) as Dispatch<ActionsParamsLoaderGlobal>
);

const RootContextProvider = ({ children }: any) => {
  const [state1, dispatch1] = useReducer<ReducerUserSession>(
    reducerSessionUser,
    initialStateSessionUser as StateSessionUser
  );

  const [state2, dispatch2] = useReducer<ReducerLoaderGlobal>(
    reducerLoaderGlobal,
    initialLoaderGlobal as StateLoaderGlobal
  );

  return (
    <SessionUserContext.Provider value={state1}>
      <SessionUserDispatchContext.Provider value={dispatch1}>
        <LoaderGlobalContext.Provider value={state2}>
          <LoaderGlobalDispatchContext.Provider value={dispatch2}>
            {children}
          </LoaderGlobalDispatchContext.Provider>
        </LoaderGlobalContext.Provider>
      </SessionUserDispatchContext.Provider>
    </SessionUserContext.Provider>
  );
};

export default RootContextProvider;
