import { useEffect, useState } from "react";
import { getTokenLocalDb } from "../../utils/utils-local-db";

const NotFoundRedirect = () => {
  const onDidMountAsync = async () => {
    // const token = await getTokenLocalDb();

    const [protocol, hostname, firstsub, secondsub] = window.location.href
      .split("/")
      .filter((part) => part !== "");

    if (firstsub === "admin" && !secondsub) {
      // don't do anything, this is unexpected.
      // check routes in useRoutes, to make sure the redirect to login is already working
    } else {
      // warning, this is intended to do a double redirect
      // then this is not login page
      location.replace(`/admin`);
    }
  };

  useEffect(() => {
    onDidMountAsync();
  }, []);

  return <div>Page Not Found.</div>;
};

export default NotFoundRedirect;
