import { AxiosResponse } from "axios";
import { authedAdminAxios } from "../../utils/axios-init";
import { StateSessionUser } from "../store-context/reducers/initial-states";

const getCheckSession = async (): Promise<null | AxiosResponse<any>> => {
  try {
    const result = await authedAdminAxios.get<StateSessionUser>(
      `admin-user/check-session`
    );

    return result;
  } catch (err401Caught: any) {
    // tododebug=1 handle autologout
    const error401Data = err401Caught?.response?.data;

    if (error401Data?.autoLogoutOn401 === true) {
      throw new Error("[401][autoLogoutOn401]");
    }
  }

  return null;
};

export default getCheckSession;
export { getCheckSession };
