import { useContext, useEffect, useState } from "react";

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Grow,
  Backdrop,
  Button,
} from "@mui/material";
import type { Color } from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SidebarContext } from "../../../app/contexts/sidebar-context";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";

import HeaderButtons from "./buttons";
import HeaderUserbox from "./userbox";
import HeaderMenu from "./menu";
import Logo from "../../../app/components/logo-sign";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background as string, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 0px) {
            left: 100%;
            width: auto;
        }
`
);

const LogoutComponent = ({ sidebarToggle }: { sidebarToggle: boolean }) => {
  const onLogOut = async () => {
    localStorage.removeItem("token");
    location.replace(`/admin`);
  };

  if (!sidebarToggle) return <></>;

  return (
    <Box
      sx={{
        padding: 1,
      }}
    >
      <Button onClick={onLogOut}>Log out</Button>
    </Box>
  );
};

function Header() {
  const [hasToken, setHasToken] = useState(false);
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  useEffect(() => {
    setHasToken(!!localStorage.getItem("token"));
  }, []);

  return (
    <HeaderWrapper
      display='flex'
      alignItems='center'
      sx={{
        boxShadow:
          theme.palette.mode === "dark"
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`,
      }}
    >
      <Stack
        direction='row'
        divider={<Divider orientation='vertical' flexItem />}
        alignItems='center'
        spacing={2}
      >
        {/* <HeaderMenu /> */}
        <Logo />
      </Stack>

      <Backdrop
        sx={{
          position: "absolute",
          width: screen.width,
          height: screen.height,
          // filter: "blur(20px)",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={sidebarToggle}
        onClick={toggleSidebar}
      >
        <></>
      </Backdrop>
      <Grow
        in={sidebarToggle && hasToken}
        style={{
          transformOrigin: "0 0 0",
          position: "absolute",
          right: 20,
          top: 50,
          // width: "100%",
          // height: "400px",
          // width: "500px",
          backgroundColor: "#fff",
        }}
        {...(sidebarToggle ? { timeout: 300 } : {})}
      >
        <Box
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 2,
          }}
        >
          <LogoutComponent sidebarToggle={sidebarToggle && hasToken} />
        </Box>
      </Grow>
      <Box display='flex' alignItems='center'>
        <Box component='span'>
          {/* <Tooltip arrow title='Toggle Menu'> */}
          <IconButton color='primary' onClick={toggleSidebar}>
            {!sidebarToggle ? (
              <MenuTwoToneIcon fontSize='small' />
            ) : (
              <CloseTwoToneIcon fontSize='small' />
            )}
          </IconButton>
          {/* </Tooltip> */}
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
